var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('h3',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.formationline", true, "Líneas de Formación"))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.formationline',"allows_crud":_vm.allows_crud}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('ButtonMedium',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_formationline'),expression:"'mesh.add_formationline'"}],attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
            'mesh.formationline',
            false,
            'Línea De Formación'
          )}`,"text_button":`Agregar`,"click_button":() => _vm.$bvModal.show(`new-formation-line-modal`),"icon":'plus',"variant":'primary'}}):_vm._e()],1)]),_c('GenericBTable',{attrs:{"items":_vm.formation_lines,"pagination":_vm.formation_lines.length,"fields":_vm.formationLinesFields,"filter":_vm.input_search,"filterCustom":_vm.filterCustom,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":_vm.display_id,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"emitChangeSearchField":_vm.changeInputSearch},scopedSlots:_vm._u([{key:"cell(faculties)",fn:function(row){return [_c('div',{staticClass:"campus-container"},_vm._l((_vm.getFacultyName(row.item.faculties)),function(item){return _c('div',{key:item.id,staticClass:"campus-div"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]}},{key:"cell(color)",fn:function(row){return [_c('div',{staticClass:"formation-line-color",style:({
            'background-color': row.item.color,
            color: row.item.color,
          })})]}},(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_formationline'),expression:"'mesh.change_formationline'"}],class:``,attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
            'mesh.formationline',
            false,
            'Línea De Formación'
          )}`,"click_button":() => _vm.$bvModal.show(`edit-formation-line-modal-${row.item.id}`),"icon":'square'}}),_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('mesh.delete_formationline'),expression:"'mesh.delete_formationline'"}],attrs:{"click_button":() => _vm.askForDeleteFormationLine(row.item.id),"tooltip_text":`Eliminar ${_vm.$getVisibleNames(
            'mesh.formationline',
            false,
            'Línea De Formación'
          )}`,"icon":'trash'}}),_c('b-modal',{attrs:{"id":`edit-formation-line-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.formationline',
            false,
            'Línea De Formación'
          )}`,"size":"md","hide-footer":""}},[_c('FormationLineForm',{attrs:{"FormationLine":row.item,"institution_id":row.item.school,"show_title":false},on:{"updated":_vm.slotUpdatedFormationLine}})],1)]}}:null],null,true)})],1),_c('b-modal',{attrs:{"id":`new-formation-line-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.formationline',
      false,
      'Línea De Formación'
    )}`,"size":"md"}},[_c('FormationLineForm',{attrs:{"institution_id":this.user.school},on:{"created":_vm.slotCreatedFormationLine}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }